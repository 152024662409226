import $ from 'jquery';

$(function() {

  $(document).ready(function(){
    init();
    topFirstView();
    fadeMenu();
    jumpHash();
    toggleMenu();
    initMap();
  });  

  window.addEventListener('resize', () => {

    let winW = $(window).width();
    if(winW > 767) {
      $('body').removeClass('is-fixed');
    }
    topFirstView();
    
  });

  window.addEventListener('scroll', () => {
    scrollTop();

    fadeMenu();
  });

  const init = () => {
    $('#loading').delay(100).fadeOut(800);
    $(".wrapper").removeClass('hidden');
      
    $('.land-header__menuItem--works, .land-header__worksMenu').on('mouseover', function() {
      $('.land-header__worksMenu').addClass('is-open');
    }).mouseout(function() {
      $('.land-header__worksMenu').removeClass('is-open');
    });

    $('.land-header__hamburgerMenuItemWork').on('click', function() {
      $(this).toggleClass('is-open');
    })
  }

  const jumpHash = () => {
    $('a[href^="#"]').click(function() {
    	const speed = 1000;
			const href= $(this).attr("href");
			const target = $(href == "#" || href == "" ? 'html' : href);
			const position = target.offset().top

			$("html, body").animate({scrollTop:position}, speed, "swing");
			return false;
    });
    
    $('.land-detail__top').click(function() {
        const id = $(this).attr('id');
        location.href= "/#" + id;
    });

    $('.land-itemLists__itemAnchor').css('top', '-' + $('.land-header').outerHeight()  + 'px');
  }

  const scrollTop = () => {
    return  $(window).scrollTop();
  }

  const toggleMenu = () => {
		$('.hamburger').click(function(){
			if($(this).hasClass('open')) {
        $(this).removeClass('open');
        $('body').removeClass('is-fixed');
        $('.headerMenu').removeClass('is-open');
        $('.land-header__hamburgerMenuItemWork').removeClass('is-open');
			} else {
        $(this).addClass('open');
        $('body').addClass('is-fixed');
				$('.headerMenu').addClass('is-open');
			}
		});
  }
  
  const initMap = () => {
    if (location.pathname === '/about/') {
      var mapPosition = {lat: 35.662823, lng: 139.702594}; 
      var mapArea = document.getElementById('land-about__mapArea');
      var mapOptions = {
        center: mapPosition,
        zoom: 16,
      };
      var map = new google.maps.Map(mapArea, mapOptions);

      var markerOptions = {
        map: map,
        position: mapPosition,
        icon: {
          url: "/assets/images/land-pin.png" ,
          scaledSize: new google.maps.Size(40, 40) ,
        }
      };
      var marker = new google.maps.Marker(markerOptions);

      var mapStyle = [ {
              "stylers": [ {
              "saturation": -100
              } ]
          } ];
          var mapType = new google.maps.StyledMapType(mapStyle);
              map.mapTypes.set( 'GrayScaleMap', mapType);
              map.setMapTypeId( 'GrayScaleMap' );
    }
  }

  const topFirstView = () => {
    let winH = $(window).innerHeight();
    $('.land-top').css('height', winH);
  } 

  const fadeMenu = () => {
    if (location.pathname !== '/') return;

   let logoBottom = $('.land-top__logo').offset().top +$('.land-top__logo').innerHeight();

   if ($(window).scrollTop() > logoBottom) {
    $('.land-header--top').addClass('is-show');
    $('.land-footer__scroll').addClass('is-show');
   }
   if ($(window).scrollTop() <= 1) {
    $('.land-header--top').removeClass('is-show');
    $('.land-footer__scroll').removeClass('is-show');
   }
  }
  
});